import DOMPurify from "dompurify";
export const FRONTEND_BASE_URL = "";
export const API_BASE_URL = "https://boards.unikwork.com/api/v1/"; // DEV
// export const API_BASE_URL = "http://192.168.29.191:3001/api/v1/"; // DEV

// export const API_BASE_URL = "https://dashboard.unikwork.com/api/v1/"; // PRODUCTION  THIS LINK USE FOR ONLY FOR PRODUCTIONS NOT FOR DAILY

// export const SOCKET_IO_URL = "http://192.168.29.191:3001/"; // dev
export const SOCKET_IO_URL = "https://boards.unikwork.com/"; //dev
// export const SOCKET_IO_URL = "https://dashboard.unikwork.com/"; // PRODUCTION

export const LOGIN = API_BASE_URL + "login";
export const LOGOUT = API_BASE_URL + "logout";
export const GET_PROFILE = API_BASE_URL + "get-profile";
export const GET_EMPLOYEES = API_BASE_URL + "get-employees";
export const ADD_EMPLOYEE = API_BASE_URL + "add-user";
export const EDIT_EMPLOYEES = API_BASE_URL + "edit-employee";
export const GET_DEPARTMENT = API_BASE_URL + "get-department";
export const GET_ROLE = API_BASE_URL + "get-role-dropdown-list";
export const GET_TECHNOLOGY = API_BASE_URL + "get-technology-dropdown-list";
export const GET_BRANCH = API_BASE_URL + "get-branch";
export const GET_CLIENT = API_BASE_URL + "get-clients";
export const ADD_CLIENT = API_BASE_URL + "add-client";
export const UPDATE_CLIENT = API_BASE_URL + "update-client";

export const GET_CONNECT = API_BASE_URL + "connect/get-connect-records";
export const GET_CONNECT_EMPLOYEE = API_BASE_URL + "connect/get-employee-dropdown-list";
export const ADD_CONNECT = API_BASE_URL + "connect/add-connect-record";
export const UPDATE_CONNECT = API_BASE_URL + "connect/update-connect-record";

export const ADD_DOCUMENT_CATEGORY = API_BASE_URL + "/document/add-document-category";
export const GET_DOCUMENT_CATEGORY = API_BASE_URL + "/document/get-document-categories";
export const DELETE_DOCUMENT_CATEGORY = API_BASE_URL + "document/delete-document-category";
export const ADD_DOCUMENT = API_BASE_URL + "document/add-documents";
export const GET_DOCUMENT = API_BASE_URL + "document/get-documents";
export const DELETE_DOCUMENT = API_BASE_URL + "document/delete-document";


export const GET_PROJECT_LEAD_TASK_STATUS = API_BASE_URL + "/task-status/get-project-lead-task-status";

export const GET_INTERN_MANUAL_LEAD_TASK_STATUS = API_BASE_URL + "/task-status/get-intern-manual-task-status";

export const GET_PROJECT_TASK_STATUS = API_BASE_URL + "/task-status/get-project-task-status";
export const ADD_PROJECT_TASK_STATUS = API_BASE_URL + "/task-status/add-project-task-status";
export const UPDATE_PROJECT_TASK_STATUS = API_BASE_URL + "/task-status/update-project-task-status";


export const GET_REPORT_CONNECT = API_BASE_URL + "report/get-connect-records";
export const GET_REPORT_CONNECT_EMPLOYEE = API_BASE_URL + "report/get-employee-dropdown-list";
export const ADD_REPORT_CONNECT = API_BASE_URL + "report/add-connect-record";
export const UPDATE_REPORT_CONNECT = API_BASE_URL + "report/update-connect-record";


export const GET_DAILY_UPDATES = API_BASE_URL + "get-daily-updates";
export const GET_DAILY_TRACKER_LOG = API_BASE_URL + "get-tracker-logs";
export const GET_PROJECT_DASHBOARD_DATA = API_BASE_URL + "get-project-dashboard-data";
export const GET_EMPLOYEE_BY_ID = API_BASE_URL + "get-employee-by-id";
export const UPDATE_USER_PROFILE = API_BASE_URL + "update-profile";
export const GET_DASHBOARD_COUNT = API_BASE_URL + "get-dashboard-count";
export const UPDATE_EMPLOYEE_STATUS = API_BASE_URL + "update-employee-status";
export const UPDATE_PROFILE = API_BASE_URL + "update-profile";
export const UPDATE_EMPLOYEE_PASSWORD = API_BASE_URL + "update-employee-password";
export const GET_EMPLOYEE_LIST_FOR_PROJECT_LEAD = API_BASE_URL + "get-employee-list-for-project-lead";
export const GET_EMPLOYEE_DROPDOWN_LIST = API_BASE_URL + "get-employee-dropdown-list";
export const GET_EMPLOYEE_DAILY_UPDATE = API_BASE_URL + "get-employee-daily-updates";
export const GET_EMPLOYEE_PROJECTS = API_BASE_URL + "get-employee-projects";
export const CREATE_INITIATE_EXIT = API_BASE_URL + "create-initiate-exit";
export const GET_INITIATE_EXIT = API_BASE_URL + "get-initiate-exit";
export const UPDATE_INITIATE_EXIT = API_BASE_URL + "update-initiate-exit";

export const GET_UPWORK_PROFILE = API_BASE_URL + "upwork-profile/get";

export const GET_EMPLOYEE_TOTAL_HOURS = API_BASE_URL + "report/get-employees-total-hours";
export const GET_EMPLOYEE_HOURS = API_BASE_URL + "report/get-employee-hours";
export const GET_EMPLOYEE_TASK = API_BASE_URL + "/report/get-employee-task";

export const GET_EMP_LIST_PROJECT = API_BASE_URL + "get-employee-list-for-project-member";
export const GET_PROJECT_MEMBERS = API_BASE_URL + "get-project-members";
export const GET_PROJECT_PAYMENT = API_BASE_URL + "get-project-payment";
export const ADD_PROJECT_MEMBERS = API_BASE_URL + "add-project-member";
export const ADD_PROJECT_PAYMENT = API_BASE_URL + "add-project-payment";
export const GET_PROJECT_MEMBER_DROPDOWN_LIST = API_BASE_URL + "get-project-member-dropdown-list";
export const REMOVE_PROJECT_MEMBER = API_BASE_URL + "remove-project-member";
export const DELETE_PROJECT_DOCUMENT = API_BASE_URL + "delete-project-document";
export const UPDATE_PROJECT_MEMBER = API_BASE_URL + "update-project-member";
export const GET_PROJECT_PENDING_REQUEST_COUNT = API_BASE_URL + "get-project-pending-request-count";

export const CREATE_TASK = API_BASE_URL + "create-task";
export const GET_TASKS = API_BASE_URL + "get-tasks";
export const DELETE_TASK_BY_ID = API_BASE_URL + "delete-task";
export const GET_TASK_BY_ID = API_BASE_URL + "get-task";
export const UPDATE_TASK = API_BASE_URL + "update-task";
export const UPLOAD_TASK_DOCUMENT = API_BASE_URL + "upload-task-document";
export const DELETE_TASK_DOCUMENT = API_BASE_URL + "delete-task-document";
export const GET_USER_TASKS = API_BASE_URL + "get-user-tasks";
export const GET_USER_TASK_BY_ID = API_BASE_URL + "get-user-task";
export const UPDATE_USER_TASK = API_BASE_URL + "update-user-task";
export const UPLOAD_USER_TASK_DOCUMENT = API_BASE_URL + "upload-user-task-document";
export const DELETE_USER_TASK_DOCUMENT = API_BASE_URL + "delete-user-task-document";
export const GET_PROJECT_LIST_FOR_MY_TASK = API_BASE_URL + "get-project-list-for-my-task";
export const GET_MEMBER_LIST_FOR_MY_TASK = API_BASE_URL + "get-member-list-for-my-task";
export const GET_EMPLOYEE_LIST_FOR_MY_TASK = API_BASE_URL + "get-employee-list-for-my-task";
export const GET_MY_REPORTING_PERSON = API_BASE_URL + "get-my-reporting-person";
export const ADD_MANUAL_TASK = API_BASE_URL + "add-manual-task";
export const GET_TASK_ACTIVITY = API_BASE_URL + "get-task-activity";
export const GET_USER_TASK_ACTIVITY = API_BASE_URL + "get-user-task-activity";
export const START_MY_TASK_TIMER = API_BASE_URL + "start-my-task-timer";
export const STOP_MY_TASK_TIMER = API_BASE_URL + "stop-my-task-timer";
export const GET_MY_TASK_TIMER = API_BASE_URL + "get-my-task-timer";
export const GET_MY_RUNNING_TASK_TIMER = API_BASE_URL + "get-my-running-task-timer";
export const GET_USER_COMPLETED_TASKS = API_BASE_URL + "get-user-completed-tasks";
export const GET_TASK_TIMER = API_BASE_URL + "get-task-timer";
export const GET_MISSED_TASK_TIMER = API_BASE_URL + "get-missed-task-timer";
export const SEND_TIMER_REGULATION_REQUEST = API_BASE_URL + "send-timer-regulation-request";
export const GET_MY_TASK_TIMER_REQUESTS = API_BASE_URL + "get-my-task-timer-requests";
export const SEND_MANUAL_TIMER_REQUESTS = API_BASE_URL + "send-manual-timer-request";
export const GET_TASK_COMMENTS = API_BASE_URL + "get-task-comments";
export const SEND_TASK_COMMENTS = API_BASE_URL + "send-task-comment";
export const GET_USER_TASK_COMMENTS = API_BASE_URL + "get-user-task-comments";
export const SEND_USER_TASK_COMMENTS = API_BASE_URL + "send-user-task-comment";
export const GET_TASK_TIMER_REQUESTS = API_BASE_URL + "get-task-timer-requests";
export const ACTION_ON_TIMER_REQUESTS = API_BASE_URL + "action-on-timer-request";
export const DELETE_TASK_COMMENTS = API_BASE_URL + "delete-task-comments";
export const DELETE_USER_TASK_COMMENTS = API_BASE_URL + "delete-user-task-comments";
export const UPDATE_PROJECT_TASKS_INDEX = API_BASE_URL + "update-project-task-index";
export const DELETE_USER_TASK = API_BASE_URL + "delete-user-task";
export const GET_EMPLOYEE_REVIEW = API_BASE_URL + "get-employee-review";
export const GET_EMPLOYEE_TRACKER_LOGS = API_BASE_URL + "get-employee-tracker-logs";

export const GET_PROJECT_DAILY_UPDATES = API_BASE_URL + "get-project-daily-updates";
export const GET_PROJECT_DOCUMENT = API_BASE_URL + "get-project-document";
export const UPLOAD_PROJECT_DOCUMENT = API_BASE_URL + "upload-project-document";
export const EDIT_PROJECT_PAYMENT = API_BASE_URL + "edit-project-payment";
export const EDIT_DAILY_UPDATE_TASK = API_BASE_URL + "edit-daily-update-task";

export const GET_PROJECT = API_BASE_URL + "get-project";
export const ADD_PROJECT = API_BASE_URL + "add-project";
export const GET_CLIENT_DROPDOWN_LIST = API_BASE_URL + "get-client-dropdown-list";
export const UPDATE_PROJECT = API_BASE_URL + "update-project";

export const GET_INBOX_LIST = API_BASE_URL + "inbox/get-inbox-list";
export const GET_ARCHIVE_INBOX_LIST = API_BASE_URL + "inbox/get-archived-inbox-list";
export const UPDATE_NOTIFICATION_FLAG = API_BASE_URL + "inbox/update-notification-flag";
export const GET_INBOX_COUNT = API_BASE_URL + "inbox/get-inbox-count";

export const GET_PROJECT_LEADS = API_BASE_URL + "get-project-leads";
export const ADD_PROJECT_LEAD = API_BASE_URL + "add-project-lead";
export const GET_PROJECT_LEAD_BY_ID = API_BASE_URL + "get-project-lead-by-id";
export const UPDATE_PROJECT_LEAD = API_BASE_URL + "update-project-lead";
export const GET_EMPLOYEE_LEAST_FOR_PROJECT_LEAD = API_BASE_URL + "get-employee-list-for-project-lead";
export const UPLOAD_PROJECT_LEAD_DOCUMENT = API_BASE_URL + "upload-project-lead-document";
export const DELETE_PROJECT_LEAD_DOCUMENT = API_BASE_URL + "delete-project-lead-document";
export const SEND_PROJECT_LEAD_COMMENT = API_BASE_URL + "send-project-lead-comment";
export const GET_PROJECT_LEAD_COMMENT = API_BASE_URL + "get-project-lead-comments";
export const GET_PROJECT_LEAD_ACTIVITY = API_BASE_URL + "get-project-lead-activity";
export const GET_PROJECT_LEAD_TIMERS = API_BASE_URL + "get-project-lead-timers";
export const DELETE_PROJECT_LEAD_COMMENT = API_BASE_URL + "delete-project-lead-comment";
export const GET_ORGANIZATION_TREE = API_BASE_URL + "organization/get-organization";
export const UPDATE_PROJECT_LEAD_INDEX = API_BASE_URL + "update-project-lead-index";

export const ADD_CANDIDATE_LEAD = API_BASE_URL + "add-candidate-lead";
export const GET_CANDIDATE_LEAD = API_BASE_URL + "get-candidate-leads";
export const GET_CANDIDATE_LEAD_BY_ID = API_BASE_URL + "get-candidate-lead-by-id";
export const UPDATE_CANDIDATE_LEAD = API_BASE_URL + "update-candidate-lead";
export const DELETE_CANDIDATE_RESUME = API_BASE_URL + "delete-candidate-resume";

export const ADD_ASSETS = API_BASE_URL + "add-asset";
export const GET_ASSETS = API_BASE_URL + "get-assets";
export const GET_EMPLOYEE_LEST_FOR_ASSETS = API_BASE_URL + "get-employee-list-for-assets";
export const GET_ASSETS_BY_ID = API_BASE_URL + "get-assets-by-id";
export const EDIT_ASSET = API_BASE_URL + "edit-asset";

export const GET_MY_TEAM = API_BASE_URL + "my-team/get-my-team";
export const GET_MY_TEAM_PROJECT = API_BASE_URL + "my-team/get-projects";
export const GET_MY_TEAM_DAILY_UPDATE = API_BASE_URL + "my-team/get-daily-updates";
export const GET_EMPLOYEE_NAME = API_BASE_URL + "my-team/get-employee-name";
export const GET_MY_TEAM_PENDING_REQUEST_COUNT = API_BASE_URL + "my-team/get-pending-request-count";
export const GET_MY_TEAM_TASKS = API_BASE_URL + "my-team/get-tasks";
export const GET_MY_TEAM_REQUEST_LIST = API_BASE_URL + "my-team/get-requests";
export const GET_MY_TEAM_TASK_BY_ID = API_BASE_URL + "my-team/get-task";
export const GET_MY_TEAM_TASK_ACTIVITY = API_BASE_URL + "my-team/get-task-activity";
export const GET_MY_TEAM_TASK_TIME_LINE = API_BASE_URL + "my-team/get-task-timer";
export const GET_MY_TEAM_TASK_COMMENTS = API_BASE_URL + "my-team/get-task-comments";
export const GET_MY_TEAM_TASK_REPORTING_PERSON = API_BASE_URL + "my-team/get-reporting-person";
export const SEND_MY_TEAM_TASK_COMMENTS = API_BASE_URL + "my-team/send-task-comment";
export const UPLOAD_MY_TEAM_TASK_DOCUMENT = API_BASE_URL + "my-team/upload-task-document";
export const DELETE_MY_TEAM_TASK_DOCUMENT = API_BASE_URL + "my-team/delete-task-document";
export const DELETE_MY_TEAM_TASK_BY_ID = API_BASE_URL + "my-team/delete-task";
export const UPDATE_MY_TEAM_TASK = API_BASE_URL + "my-team/update-task";
export const ACTION_ON_MY_TEAM_TASK_TIMER_REQUESTS = API_BASE_URL + "my-team/action-on-timer-request";
export const ADD_MY_TEAM_TASK = API_BASE_URL + "my-team/create-task";
export const DELETE_MY_TEAM_TASK_COMMENT = API_BASE_URL + "my-team/delete-task-comment";
export const REVIEW_VERIFY_PASSWORD = API_BASE_URL + "reviews/verify-password";
export const GET_MY_TEAM_REVIEW_BY_ID = API_BASE_URL + "reviews/get-user-reviews";
export const ADD_USER_REVIEW = API_BASE_URL + "reviews/add-user-review";
export const GET_REVIEW_QUESTIONS = API_BASE_URL + "reviews/get-review-questions";
export const GET_MY_TEAM_TRACKER_LOG = API_BASE_URL + "my-team/get-tracker-logs";

export const GET_TRAINEE = API_BASE_URL + "trainee/get-trainees";
export const GET_TRAINEE_TASK = API_BASE_URL + "trainee/get-tasks";
export const GET_TRAINEE_REPORTING_PERSON = API_BASE_URL + "trainee/get-reporting-person";
export const GET_TRAINEE_TASK_BY_ID = API_BASE_URL + "trainee/get-task";
export const TRAINEE_UPDATE_TASK_INDEX = API_BASE_URL + "trainee/update-task-index";
export const TRAINEE_UPDATE_TASK = API_BASE_URL + "trainee/update-task";
export const TRAINEE_TASK_CREATE = API_BASE_URL + "trainee/create-task";
export const TRAINEE_DELETE_TASK_BY_ID = API_BASE_URL + "trainee/delete-task";
export const TRAINEE_DELETE_TASK_DOCUMENT = API_BASE_URL + "trainee/delete-task-document";
export const TRAINEE_UPLOAD_TASK_DOCUMENT = API_BASE_URL + "trainee/upload-task-document";
export const GET_TRAINEE_TASK_COMMENTS = API_BASE_URL + "trainee/get-task-comments";
export const DELETE_TRAINEE_TASK_COMMENT = API_BASE_URL + "trainee/delete-task-comment";
export const SEND_TRAINEE_TEAM_TASK_COMMENTS = API_BASE_URL + "trainee/send-task-comment";
export const GET_TRAINEE_TEAM_TASK_TIME_LINE = API_BASE_URL + "trainee/get-task-timer";
export const GET_TRAINEE_TEAM_TASK_ACTIVITY = API_BASE_URL + "trainee/get-task-activity";


export const MY_TRAINING_DELETE_TASK_DOCUMENT = API_BASE_URL + "my-training/delete-task-document";
export const MY_TRAINING_UPLOAD_TASK_DOCUMENT = API_BASE_URL + "my-training/upload-task-document";
export const GET_MY_TRAINING_TASK = API_BASE_URL + "my-training/get-tasks";
export const GET_MY_TRAINING_TASK_BY_ID = API_BASE_URL + "my-training/get-task";
export const MY_TRAINING_UPDATE_TASK_INDEX = API_BASE_URL + "my-training/update-task-index";
export const MY_TRAINING_UPDATE_TASK = API_BASE_URL + "my-training/update-task";
export const MY_TRAINING_TASK_CREATE = API_BASE_URL + "my-training/create-task";
export const GET_MY_TRAINING_TASK_COMMENTS = API_BASE_URL + "my-training/get-task-comments";
export const DELETE_MY_TRAINING_TASK_COMMENT = API_BASE_URL + "my-training/delete-task-comment";
export const SEND_MY_TRAINING_TASK_COMMENTS = API_BASE_URL + "my-training/send-task-comment";
export const GET_MY_TRAINING_TASK_TIME_LINE = API_BASE_URL + "my-training/get-task-timer";
export const GET_MY_TRAINING_TASK_ACTIVITY = API_BASE_URL + "my-training/get-task-activity";

export const GET_TASK = API_BASE_URL + "get-task";
export const GET_TASK_DOCUMENT = API_BASE_URL + "get-task-documents";

export const GET_ALL_BOOKS = API_BASE_URL + "book/get-books";
export const ADD_BOOK = API_BASE_URL + "book/add-book";
export const EDIT_BOOK = API_BASE_URL + "book/edit-book";
export const DELETE_BOOK = API_BASE_URL + "book/delete-book";
export const ISSUED_BOOK = API_BASE_URL + "book/issue-book";
export const RETURN_BOOK = API_BASE_URL + "book/return-book";
export const GET_ISSUED_BOOK = API_BASE_URL + "book/get-issued-books";


export const GET_PROJECT_DROPDOWN_LIST = API_BASE_URL + "get-project-dropdown-list";
export const ADD_DAILY_UPDATE = API_BASE_URL + "add-daily-update";

export const DELETE_COMPANY_BYADMIN = API_BASE_URL + "delete-company-by-admin";
// export const COMPANY_SIGN_UP = API_BASE_URL + "edit-company";
// export const GET_COMPANYS = API_BASE_URL + "get-companys";
// export const LOGOUT = API_BASE_URL + "logout";
// export const UPDATE_COMPANY_PROFILE_ADMIN = API_BASE_URL + "update-company-by-admin?company_id=";
// export const GET_ADMIN_PROFILE = API_BASE_URL + "get-admin-profile";
// export const GET_COMPANY_PROFILE = API_BASE_URL + "get-company-profile";
// export const GET_MEMBER_PROFILE = API_BASE_URL + "get-member-profile";
// export const GET_HUNTER_PROFILE = API_BASE_URL + "get-hunter-profile";
// export const GET_ADMIN_DASHBOARD = API_BASE_URL + "get-status-count-admin";
// export const GET_DASHBOARD_SCAN_ADMIN = API_BASE_URL + "get-scan-count-by-admin";

//key for recaptcha
export const SITE_KEY = "6LdyQKApAAAAAITPSpA_-kSOU2YjXwiVLsrtMluk";

// for sanitize input

export const sanitizedContent = (htmlContent) => {
  const userContent = htmlContent || "-";
  const sanitizedContent = DOMPurify.sanitize(userContent, {
    USE_PROFILES: { html: true },
  });
  return sanitizedContent;
};
